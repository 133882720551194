<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.go(-1)">学员题库管理(小程序)</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >学员题库列表-{{ questionInfo.questionBankName }}</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="题目类型" class="searchboxItem ci-full">
              <span class="itemLabel">题目类型:</span>
              <el-select size="small" v-model="questionType" clearable  placeholder="请选择题目类型">
                <el-option
                  v-for="item in questionTypeSelectData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="题号"
                align="center"
                prop="questionOrder"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="题目类型"
                align="center"
                prop="questionType"
                show-overflow-tooltip
                min-width="100"
                ><template slot-scope="scope">
                  <span v-if="scope.row.questionType == 0">单选</span>
                  <span v-else-if="scope.row.questionType == 1">多选</span>
                  <span v-else-if="scope.row.questionType == 2">判断</span>
                  <span v-else-if="scope.row.questionType == 3">填空</span>
                  <span v-else>- -</span>
                </template>
              </el-table-column>
              <el-table-column
                label="错题人数"
                align="center"
                prop="totalNumber"
                show-overflow-tooltip
                min-width="100"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "studentQuestionList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 上页传过来的题库info
      questionInfo: {},
      // 搜索条件 - 题目类型
      questionType: "",
      // 题目类型的下拉数据
      questionTypeSelectData: [
        {
          value: "0",
          label: "单选",
        },
        {
          value: "1",
          label: "多选",
        },
        {
          value: "2",
          label: "判断",
        },
        {
          value: "3",
          label: "填空",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.questionInfo = this.$route.query || {};
  },
  methods: {
    // 初始化 - 获取学员题库列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        questionBankId: this.$route.query.questionBankId,
        questionType: this.questionType
      };
      this.doFetch(
        {
          url: "/miniapp/question/learn/error/list",
          params,
          pageNum,
        },
        true,
        2
      );
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {},
};
</script>
<style lang="less" scoped></style>
